import { useMutation, useQuery } from 'react-query'
import { filterFetch, normalFetch } from '../../fetch-api/get'
import { postApi } from '../../fetch-api/post'
import { putApi } from '../../fetch-api/put'
import { useMainContext } from '../../../contexts/main'
import { useQueryContext } from '../../../contexts/query'
import { makeQueryFilters } from '../../../utils/query-maker'

export const stakingKeys = {
  getStakingPlans: 'getStakingPlans',
  addStakingPlan: 'addStakingPlan',
  getStakingReports: 'getStakingReports',
  getUserStake: 'getUserStake',
  addUserStake: 'addUserStake',
}

export const useGetStakingPlans = () => {
  return useQuery({
    queryFn: () => normalFetch('settings/staking'),
    queryKey: [stakingKeys.getStakingPlans],
    select: (res) => res?.data?.data,
  })
}

export const useAddStakingPlan = (onSuccess = () => { }) => {
  const { setToast, queryClient } = useQueryContext()

  return useMutation({
    mutationFn: (p) => putApi(p, 'settings/staking'),
    mutationKey: [stakingKeys.addStakingPlan],
    onSuccess: () => {
      queryClient.invalidateQueries([stakingKeys.getStakingPlans])
      setToast({
        message: 'plan-added-success',
        show: true,
      })
      onSuccess()
    },
  })
}

export const useGetStakingReports = (filters, pagination, extra, csv) => {
  const { lang } = useMainContext()
  const newFilters = { ...extra, ...filters }
  const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
  if (csv) {
    params.export = 'csv'
  }

  return useQuery({
    queryFn: () => filterFetch(params, 'staking/admin'),
    queryKey: stakingKeys.getStakingReports,
    select: (res) => res?.data,
  })
}

export const useGetUserStake = (id) => {
  return useQuery({
    queryFn: () => normalFetch(`staking/admin/${id}`),
    queryKey: [stakingKeys.getUserStake, id],
    enabled: !!id,
    select: (res) => res?.data?.data,
  })
}

export const useAddUserStake = () => {
  const { setToast } = useQueryContext()

  return useMutation({
    mutationFn: (payload) => postApi(payload, 'staking/admin'),
    mutationKey: [stakingKeys.addUserStake],
    onSuccess: () => {
      setToast({ message: 'operation-success', show: true })
      onSuccess()
    },
  })
}
