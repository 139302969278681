import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ListLayout from '../../../components/layout/main/ListLayout'
import StakingCard from '../../../components/main/setting/staking/StakingCard'
import { useGetStakingPlans, useGetUserStake } from '../../../core/services/react-query/staking'

const StakingDetails = () => {
  const params = useParams()

  const { data: plans } = useGetStakingPlans()
  const { data: userStake } = useGetUserStake(params?.id)

  const userPlan = useMemo(() => {
    if (!!userStake && !!plans) {
      return plans.find((x) => x.id === userStake.planId)
    }
  }, [plans, userStake])

  return (
    <ListLayout>
      <div className={'flex items-center justify-center dark:text-gray-300'}>
        <StakingCard item={userPlan} idx={1} isDetail={true} user={userStake} />
      </div>
    </ListLayout>
  )
}

export default StakingDetails
